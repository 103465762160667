/* ================================= Range Slider Css Start =========================== */
.custom--range {
	#slider-range {
		height: 4px;
		border: 0;
		background: var(--gray-200);
        margin: 8px;
		.ui-slider-handle {
			width: 16px !important;
			height: 16px !important;
			background-color: var(--main-600) !important;
			border-radius: 50%;
			top: 50%;
			transform: translateY(-50%);
			border: 0 !important;
			outline: 0 !important;
			transition: .2s linear;
			&:hover, &.ui-state-active {
				transform: translateY(-50%) scale(1.3);
			}
			&.ui-state-active {
                box-shadow: 0px 0px 12px 4px #95959541 !important;  
			}
		}
		.ui-widget-header {
			background-color: var(--main-600);
            transition: .2s linear;
		}
		span {
			&:focus {
				background-color: var(--main-600);
			}
		}
	}
    &__prices{
        width: 58px;
    }
	input {
		border: 0;
		color: hsl(var(--body-color));
		font-weight: 500;
		outline: 0;
	}
}
/* ================================= Range Slider Css End =========================== */