/* ======================= Offer Css Start =========================== */
.offer-card {
    &__thumb {
        img {
            @include xxl-screen {
                max-width: 246px;
                width: 100%;
            }
            @include lg-screen {
                max-width: 163px;
            }
        }
    }
}
/* ======================= Offer Css End =========================== */