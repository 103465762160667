/* Convert to Rem start */
$font-base: 16px;
@function rem($target, $context: $font-base) {
  @if ($target == 0) {
    @return 0;
  }
  @return calc($target / $context * 1rem);
}
/* Convert to Rem End */


/* ======================== Functions Css End ======================= */
// Convert px to REM 
@function convertToRem($px) {
  $val: calc($px / 16);
  @return $val;
}
@function formatFloatingNumber($number) {
  @return calc(round($number * 1000) / 1000);
}
@function clampCal($minSize, $maxSize, $minWidth: 768, $maxWidth: 1399) {
  $minREMsize: convertToRem($minSize);
  $maxREMsize: convertToRem($maxSize);
  $minREMwidth: convertToRem($minWidth);
  $maxREMwidth: convertToRem($maxWidth);

  $slopFontCal: calc($maxREMsize - $minREMsize);
  $slopWidthCal: calc($maxREMwidth - $minREMwidth);
  $slop: calc($slopFontCal / $slopWidthCal);

  $minusWidth: calc($minREMwidth * -1);
  $xAxisIntersection: calc($minusWidth * $slop + $minREMsize);
  $preferred: calc($slop * 100);

  $xAxisMax: formatFloatingNumber($xAxisIntersection);
  $preferredMax: formatFloatingNumber($preferred);

  @return clamp(
    #{$minREMsize}rem,
    #{$xAxisMax}rem + #{$preferredMax}vw,
    #{$maxREMsize}rem
  );
}


// Use of this function, Please Uncomment this class and see how it works 
// .demoClass {
//   padding: clampCal(24, 40);
//   font-size: clampCal(20, 48);
// }
// ============ If You need all properties you can use like this way ============ 
// .demoClass {
//   padding: clampCal(24, 40, 424, 991); // Here Properties are small device padding, Large device padding, small device width, Large device width   
// }
/* ======================== Functions Css End ======================= */

