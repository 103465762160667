
/* ================================= Classes Css Start =========================== */
/* Section Background */
.bg-color {
    &-one {
        background-color: var(--bg-color-one) !important;
    }
    &-two {
        background-color: var(--bg-color-two) !important;
    }
    &-three {
        background-color: var(--bg-color-three) !important;
    }
    &-neutral {
        background-color: var(--neutral) !important;
    }
}

.container-lg {
    max-width: 1680px;
}

/* Column Extra Small Screen */
.col-xs-6 {
    @media screen and (min-width: 425px) and (max-width: 575px) {
        width: 50%;
    }
}

@media (min-width: 424px) {
    .d-xs-flex {
        display: flex !important;
    }
}

.opacity {
    &-6 {
        opacity: .06 !important;
    }
}

/* Transition */
.transition {
    &-1 {
        transition: .1s linear;
    }
    &-2 {
        transition: .2s linear;
    }
    &-3 {
        transition: .3s linear;
    }
}

.min-width-max-content {
    min-width: max-content;
}

.inset {
    &-inline-start-0 {
        inset-inline-start: 0 !important;
    }
    &-inline-start-100 {
        inset-inline-start: 100% !important;
    }
    &-inline-start-auto {
        inset-inline-start: auto !important;
    }
    &-inline-end-0 {
        inset-inline-end: 0 !important;
    }
    &-inline-end-auto {
        inset-inline-end: auto !important;
    }
    &-block-start-0 {
        inset-block-start: 0 !important;
    }
    &-block-end-0 {
        inset-block-end: 0 !important;
    }
}

.start {
    &-auto {
        inset-inline-start: auto !important;
    }
}

.end {
    &-auto {
        inset-inline-end: auto !important;
    }
}

.line-height {
    &-0 {
        line-height: 0;
    }
    &-1 {
        line-height: 1;
    }
}

/* Text Color */
.hover-text {
    &-decoration-underline {
        &:hover {
            text-decoration: underline;
        }
    }
    &-decoration-none {
        &:hover {
            text-decoration: none;
        }
    }
}

.font {
    &-heading {
        font-family: var(--heading-font);
    }
    &-heading-two {
        font-family: var(--heading-font-two);
    }
    &-body {
        font-family: var(--body-font);
    }
}

/* Bg Image Css */
.bg-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    width: 100%;
    height: 100%;
}

.list-inside {
    list-style: inside !important;
}
.aspect-ratio-1 {
    aspect-ratio: 1;
}
.cursor-pointer {
    cursor: pointer;
}
/* ================================= Classes Css End =========================== */