/* ================================= preload Css Start =========================== */
.preloader {
  background-color: hsl(var(--white));
  inset-block-end: 0;
  inset-inline-start: 0;
  position: fixed;
  inset-inline-end: 0;
  inset-block-start: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* ================================= preload Css End ===========================  */
