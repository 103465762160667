// border
@each $color, $shades in $colors {
    @each $shade, $value in $shades {
      .border-#{$color}-#{$shade} {
        border-color: var(--#{$color}-#{$shade}) !important;
      }
    }
}

// hover border
@each $color, $shades in $colors {
    @each $shade, $value in $shades {
      .hover-border-#{$color}-#{$shade} {
        &:hover {
            border-color: var(--#{$color}-#{$shade}) !important;
        }
      }
    }
}

.border {
  &-dashed {
    border-style: dashed !important;
  }
  &-dotted {
    border-style: dotted !important;
  }
}
