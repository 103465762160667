.scroll-sm {
    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b1b3b8;
      &:hover {
        background-color: #7e7d7d;
      }
    }
    &::-webkit-scrollbar-track {
      background-color: #d6d9de;
    }
}