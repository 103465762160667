.search-box {
    position: fixed;
    width: 100%;
    height: 100%;;
    z-index: 9;
    background: hsl(var(--black));
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: .2s linear;
    transform: scale(.8);
    z-index: 9;
    &.active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
}