/* ============================ trending products css Start =========================== */
.trending-products {
    &-box {
        background-color: #F3EDDE;
        &__title {
            max-width: 364px;
            margin: 0 auto;
        }
        &__content {

        }
    }
}
/* ============================ trending products css End =========================== */