.g-8, .gy-8 {
    --bs-gutter-y: .5rem !important;
}
.g-12, .gy-12 {
    --bs-gutter-y: .75rem !important;
}


.g-8, .gx-8 {
    --bs-gutter-x: .5rem !important;
}
.g-12, .gx-12 {
    --bs-gutter-x: .75rem !important;
}