
/* Box shadow start */
.box-shadow {
    &-sm {
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);  
    }
    &-md {
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);  
    }
    &-lg {
      box-shadow: 0px 7px 21px -5px rgb(0 0 0 / 10%), 0 4px 21px -4px rgb(0 0 0 / 10%);  
    }
    &-xl {
      box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);  
    }
    &-2xl {
      box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);  
    }
    &-inner {
      box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);  
    }
}
/* Box shadow End */
