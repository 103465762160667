/* =========================== header Middle Css Start =============================== */
.header-middle {
    padding: 30px 0;
    &.style-two {
        padding: 24px 0;
    }
    @include xl-screen {
        padding: 24px 0;
    }
    @include lg-screen {
        padding: 16px 0 !important;
    }
    @include md-screen {
        padding: 8px 0 !important;
    }

    .location-box {
        @include lg-screen {
            padding: 6px 8px !important;
        }
        @include sm-screen {
            padding-left: 14px !important;
        }
    }
}

.form-location-wrapper {
    gap: clampCal(8, 16, 1199, 1599);
}

/* Search Form Css Here */
.search-form {
    @include md-screen {
        .select2-selection.select2-selection--single {
            border-radius: 50px !important;
            border-radius: 50px !important;
            background-color: hsl(var(--white)) !important;
            border: 1px solid var(--gray-100) !important;
        }
    }
    &__wrapper {
        @include md-screen {
            display: none;
        }
    }
    &__input {
        border-color: var(--gray-200) !important;
        width: 520px;
        @include xxl-screen {
            width: 400px;
        }
        @include xl-screen {
            width: 300px;
        }
        @include lg-screen {
            width: 200px;
        }
    }
}

/* Header Activities Css Start */
.header-two-activities {
    @include xl-screen {
        gap: 24px !important;
    }
    @include md-screen {
        gap: 16px !important;
    }
    @include sm-screen {
        gap: 10px !important;
    }

    @include md-screen {
        .item-hover-two {
            > span {
                font-size: 18px !important;
                color: var(--neutral-600) !important;
            }
        }
    }
}
/* Header Activities Css End */


/* Header Activities Css start */
.search-category {
    &.style-two {
        background-color: hsl(var(--white));
        border-radius: 8px;
        overflow: hidden;
        .select2-container--default .select2-selection--single {
            border-radius: 0;
            border-left: 0 !important;
            border-top: 0 !important;
            border-bottom: 0 !important;
        }
    }
}
/* Header Activities Css End */


/* Category Dropdown Responsive Css Start */
@include md-screen {
    .responsive-dropdown {
        position: fixed;
        inset-inline-start: 0;
        inset-block-start: 0;
        visibility: visible;
        opacity: 1;
        margin-top: 0;
        border-radius: 0;
        width: unset !important;
        height: 100vh;
        overflow-y: auto;
        transform: translateX(-100%);
        padding-top: 8px !important;
        &.active {
            transform: translateX(0);
        }
        ul {
            max-height: 100% !important;
            li {
                &.active {
                    > a {
                        background: var(--gray-50);
                        color: var(--gray-800) !important;
                        font-weight: 600;
                        .icon {
                            transform: rotate(90deg);
                        }
                    }
                }
                a {
                    border-bottom: 1px solid var(--gray-100);
                }
            }
        }
        .submenus-submenu{
            position: static;
            visibility: visible;
            opacity: 1;
            box-shadow: none;
            border-radius: 0;
            margin-left: 40px;
            width: unset;
            padding-top: 8px !important;
            display: none;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            &__title {
                display: none;
            }
            &__list {
                max-height: unset !important;               
                li {
                    a {
                        padding-left: 16px;
                        &::before {
                            inset-inline-start: 0;
                        }
                    }
                }
            }
        }
    }
}
/* Category Dropdown Responsive Css End */
/* =========================== header Middle Css End =============================== */
