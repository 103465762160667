
/* ============================ Extend Css Start ======================== */
/* Cover image Css */
  .cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;  
  }
/* Display Flex Css Start */ 
  .flex-align {
    display: flex;
    align-items: center;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
/* Display Flex Css End */
/* ============================ Extend Css End ======================== */

