/* Home Two Css Start */
.color-two {
    &:root {
        --main-h: 24;
        --main-s: 100%;
        --main-l: 49%;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: var(--heading-font-two);
    }
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        font-family: var(--heading-font-two);
    }
}
/* Home Two Css End */
